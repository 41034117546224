import md5 from 'md5';

const authProvider = {
    // This is called when the user attempts to login
    login: ({ username, password }) => {
        console.log("MD5", md5('dog-cat-mouse-chicken'));
        console.log("MD5 password", md5(password));
 
        // Only set the username in localStorage if the username and password match the expected values
        if (username === 'Valid8' && 'a978f7f97e25f672e27cd6e149888555' === md5(password)) {
            localStorage.setItem('username', username);
            return Promise.resolve();
        } else {
            return Promise.reject();
        }
    },

    // called when the user clicks on the logout button
    logout: () => {
        localStorage.removeItem('username');
        return Promise.resolve();
    },

    // called when the API returns an error
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('username');
            return Promise.reject();
        }
        return Promise.resolve();
    },

    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        return localStorage.getItem('username')
            ? Promise.resolve()
            : Promise.reject();
    },

    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => {
        const username = localStorage.getItem('username');
        if (username === 'Valid8') {
            // Assuming 'Valid8' has 'admin' permissions, adjust as per your requirement.
            return Promise.resolve('admin');
        } else {
            // For any other user, you can reject or assign different permissions.
            // Here, we are giving 'user' permissions to everyone else.
            return Promise.resolve('user');
        }
    },
};

export default authProvider;
