/**
 * Valid Report 
 * 
 * Collects all the information about surveys from the MongoDB database 
 * and transforms the information to be able to display various charts
 * 
 * Please note: this script needs to be TypeScript as the chart2 plugin is in TypeScript as well.
 * 
 * It is using the extension react-chartjs-2: 
 * https://www.creative-tim.com/learning-lab/nextjs/react-chartjs-2/argon-dashboard
 * 
 * It is also using chart.js:
 * https://www.chartjs.org/docs/next/
 * 
 * 
 * This script has a few sections:
 * 
 * - LOADING DATA FROM DATABASE
 * - SECTION: PIE CHART - ALL SURVEYS
 * - SECTION: YESNO BAR CHART
 *   --> this section is the more complex one as it need to collect all the information in different variables to be able to display various scenarios
 * 
 */

import React, { useCallback, useRef } from "react";
import { useLocation } from 'react-router-dom';
import { useQuery, Loading } from 'react-admin';
import Select from 'react-select';
import { format } from 'date-fns';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import {yesNoQuestions, allAddresses} from "../assets/yesnoQuestions";

import { toPng } from 'html-to-image';

// CSS
import './validReports.css';

// CHARTS
import {
    Chart as ChartJS,
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Doughnut, Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';


ChartJS.register(
    ChartDataLabels,
    CategoryScale,
    ArcElement,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );




// REPORT COMPONENT
const ValidReports = () => { 


    let streetSelectObj: SelectObjectType;
    streetSelectObj = [];

    let addressesCollected: string[] = [];

    Object.entries(allAddresses).forEach(([v, i]) => {
      i.forEach(function(a: string){
        streetSelectObj.push({"value":a,  "label":a});
      })
    })

    // URL Param "stat" - Decides which section we'll be handling ('yesno'...)
    const search = useLocation().search;
    const statSection = new URLSearchParams(search).get('stat');

    const [yesnoTitle, setYesnoTitle] = React.useState('Yes/No/Not Sure over the year');
    const [yesnoValue, setYesnoValue] = React.useState('yesno-0');  // question 0 doesn't exist but this creates an empty set before any question is selected
    const currentYear = format(new Date(), 'yyyy');

    const [selectionYear, setSelectionYear]         = React.useState(currentYear);
    const [selectionAddress, setSelectionAddress]   = React.useState('default-address');
    const [selectionProvider, setSelectionProvider] = React.useState('default-provider');
    const [addressChartShow, setAddressChartShow]   = React.useState(false);
    const [providerChartShow, setProviderChartShow] = React.useState(false);
    const [streetSelect, setStreetSelect]           = React.useState(streetSelectObj);

    const [q17checked, setQ17Checked] = React.useState(false);

    const ref = useRef<HTMLDivElement>(null)

    streetSelectObj = [];


    // PRINT DOCUMENT TO IMAGE
    const onButtonClick = useCallback(() => {
      if (ref.current === null) {
        console.log("NO REFERENCE SET ... ");
        return
      }
      const dateToday   = format(new Date(), 'dd-MM-yyyy--hh:mm:ss');
      
      toPng(ref.current, { cacheBust: true, backgroundColor: '#FFFFFF' })
        .then((dataUrl) => {
          const link = document.createElement('a')
          link.download = 'Valid-Report-' + dateToday + '.png'
          link.href = dataUrl
          link.click()
        })
        .catch((err) => {
          console.log(err)
        })
    }, [ref])
  


    //* -- LOADING DATA FROM DATABASE

    // REGULAR SURVEYS
    let queryObj1 = useQuery({ 
        type: 'getList',
        resource: 'savedsurveys',
        payload: { pagination: { page: 1 , perPage: 9999 }, sort: { field: 'id', order: 'ASC' }, filter: {} }
    });
    let RSdata    = queryObj1.data;
    let RStotal   = queryObj1.total;
    let RSloading = queryObj1.loading;
    let RSerror   = queryObj1.error;

    // SURVEYS NOT TAKEN
    let queryObj2 = useQuery({ 
        type: 'getList',
        resource: 'surveysnottaken',
        payload: { pagination: { page: 1 , perPage: 9999 }, sort: { field: 'id', order: 'ASC' }, filter: {} }
    });
    let NTdata    = queryObj2.data;
    let NTtotal   = queryObj2.total;
    let NTloading = queryObj2.loading;
    let NTerror   = queryObj2.error;
    

    if (RSloading || NTloading)  return <Loading />;
    if (RSerror)    return <p>Error loading data of all surveys.</p>; 
    if (NTerror)    return <p>Error loading data of Not Taken Surveys.</p>; 
    if (RSdata.length === 0)    return <p>There don't seem to be any survey available to create any report. Please complete surveys first.</p>;


    if (!NTdata)    console.log("DATA FOR NOT TAKEN SURVEYS DOESN'T EXIST...")


    /* SECTION: PIE CHART - ALL SURVEYS
    ----------------------------------------------------------------------------------------------------------------------------- */
    const cuOptions = {
      plugins: {
        title: {
          display: true,
          text: 'All Surveys in a glance',
        },
        datalabels: {
           display: true,
           color: 'red'
        },
      },
      responsive: true,
      showAllTooltips: true
    };

    // initialise 
    let   complTotal     = 0;
    let   uncplTotal     = 0;
    let   nottakenTotal  = NTtotal;
    
    if ( RStotal ) {
        RSdata.forEach(function(current: { completed: any; }){
            if ( current.completed ) complTotal++;
        })
        
        uncplTotal = RStotal - complTotal;
    }

    const cuCharData = {
        labels: ['Completed', 'Uncompleted', 'Not taken'],
        datasets: [
          {
            label: 'All surveys',
            data: [complTotal, uncplTotal, nottakenTotal],
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 100, 35, 0.2)',
            ],
            borderColor: [
              'rgba(54, 162, 235, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(54, 162, 235, 1)',
            ],
            borderWidth: 1,
          },
        ],
      };


    /* SECTION: YESNO BAR CHART
    ----------------------------------------------------------------------------------------------------------------------------- */

    const yesnoBarLabels  = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    
    // Create relation between Year - Month - increment for each question
    type NumberArrType = Array<number>;

    type DataType     = Record<string, Record<string, Record<string, NumberArrType>>>;                  // question > Year > Month = [0,0,0]
    type extDataType  = Record<string, Record<string, Record<string, Record<string, NumberArrType>>>>;  // address/provider > question > Year > Month = [0,0,0]
    type q17DataType  = Record<string, Record<string, Record<string, Record<string, Array<string>>>>>;  // provider > address > Year > Month = Array(strings) (comment for q17)

    type YearDataType     = Record<string, string>;
    type SelectObjectType = Array<YearDataType>;

    let yearSelectObj: SelectObjectType;
    yearSelectObj = [];
    let providerSelectObj: SelectObjectType;
    providerSelectObj = [];

    const defaultYearSelect: SelectObjectType = [{"value": currentYear, "label": currentYear}];

    let yesnoData: DataType;
    let q17: q17DataType;
    let addressYesNoData: extDataType;
    let providerYesNoData: extDataType;
    yesnoData = {};
    q17 = {};
    providerYesNoData = {};
    addressYesNoData = {};
    addressYesNoData['default-address'] = {};
    providerYesNoData['default-provider'] = {};


    type yearArrayType  = Array<string>;
    type regArrayType   = Array<string>;

    let yearSelectArray:yearArrayType   = []; // this one will keep track of all years occuring in the surveys and is used for dropdown at the end
    let streetArray:regArrayType        = []; // collecting street names for dropdown selection
    let providerArray:regArrayType        = []; // collecting street names for dropdown selection


    // Check all data and collect information ("if RStotal" means that data is not empty)
    if ( RStotal ) {

      // loop through each survey received from the database 
      RSdata.forEach(function(curnt: { createdAt: any; 
                                    data: any;
                                    street: string;
                                   }){
          let dateY = format(new Date(curnt.createdAt), 'yyyy');
          let dateM = format(new Date(curnt.createdAt), 'LLLL');

          // populate provider array for dropdown
          if ( typeof curnt.data['Service provider'] !== 'undefined' && providerArray.indexOf(curnt.data['Service provider']) === -1 ) {
            providerArray.push(curnt.data['Service provider']);
            providerSelectObj.push({"value":curnt.data['Service provider'],  "label":curnt.data['Service provider']});
            providerYesNoData[curnt.data['Service provider']] = {};
          }

          // populate street array for dropdown
          if ( typeof curnt.street !== 'undefined' && streetArray.indexOf(curnt.street) === -1 ) {
            streetArray.push(curnt.street);
            streetSelectObj.push({"value":curnt.street,  "label":curnt.street});
            addressesCollected.push(curnt.street)
            addressYesNoData[curnt.street] = {};
          }

          // populate year data for dropdown
          if ( yearSelectArray.indexOf(dateY) === -1 ) {
            yearSelectArray.push(dateY);
            yearSelectObj.push({"value":dateY,  "label":dateY});
          }
          

          let i = 0;
          let questIdx: string = '';

          // loop through each question (yesno-1 to yesno-20)
          for (i=0; i <= 20; i++) {

              // there is no yesno for question 17
              if ( i === 17 ) {

                if ( typeof q17[curnt.data['Service provider']] === 'undefined' ) q17[curnt.data['Service provider']] = {};

                if ( typeof q17[curnt.data['Service provider']]['default-address'] === 'undefined' ) q17[curnt.data['Service provider']]['default-address'] = {};
                if ( typeof q17[curnt.data['Service provider']]['default-address'][dateY] === 'undefined' ) {
                  q17[curnt.data['Service provider']]['default-address'][dateY] = {};
                  // eslint-disable-next-line no-loop-func
                  yesnoBarLabels.forEach(function(m: string){
                    q17[curnt.data['Service provider']]['default-address'][dateY][m] = [];
                  })
                }

                if ( typeof q17[curnt.data['Service provider']][curnt.street] === 'undefined' ) q17[curnt.data['Service provider']][curnt.street] = {};
                if ( typeof q17[curnt.data['Service provider']][curnt.street][dateY] === 'undefined' ) {
                  q17[curnt.data['Service provider']][curnt.street][dateY] = {};
                  // eslint-disable-next-line no-loop-func
                  yesnoBarLabels.forEach(function(m: string){
                    q17[curnt.data['Service provider']][curnt.street][dateY][m] = [];
                  })
                }

                q17[curnt.data['Service provider']][curnt.street][dateY][dateM].push(curnt.data['question76']);

                continue;
              }

              // Question Index and initializing yesnoData
              questIdx = "yesno-" + i;

              

              if ( typeof yesnoData[questIdx] === 'undefined' ) yesnoData[questIdx] = {};

              if ( typeof providerYesNoData[curnt.data['Service provider']][questIdx] === 'undefined' ) providerYesNoData[curnt.data['Service provider']][questIdx] = {};
              if ( typeof providerYesNoData['default-provider'][questIdx] === 'undefined' ) providerYesNoData['default-provider'][questIdx] = {};

              if ( typeof addressYesNoData[curnt.street][questIdx] === 'undefined' ) addressYesNoData[curnt.street][questIdx] = {};
              if ( typeof addressYesNoData['default-address'][questIdx] === 'undefined' ) addressYesNoData['default-address'][questIdx] = {};


              if ( typeof yesnoData[questIdx][dateY] === 'undefined' ) {
                yesnoData[questIdx][dateY] = {};
                // eslint-disable-next-line no-loop-func
                yesnoBarLabels.forEach(function(m: string){
                  yesnoData[questIdx][dateY][m] = [0,0,0];
                })
              }

              if ( typeof providerYesNoData[curnt.data['Service provider']][questIdx][dateY] === 'undefined' ) {
                providerYesNoData[curnt.data['Service provider']][questIdx][dateY] = {};
                // eslint-disable-next-line no-loop-func
                yesnoBarLabels.forEach(function(m: string){
                  providerYesNoData[curnt.data['Service provider']][questIdx][dateY][m] = [0,0,0];
                })
              }
              if ( typeof providerYesNoData['default-provider'][questIdx][dateY] === 'undefined' ) {
                providerYesNoData['default-provider'][questIdx][dateY] = {};
                // eslint-disable-next-line no-loop-func
                yesnoBarLabels.forEach(function(m: string){
                  providerYesNoData['default-provider'][questIdx][dateY][m] = [0,0,0];
                })
              }

              if ( typeof addressYesNoData[curnt.street][questIdx][dateY] === 'undefined' ) {
                addressYesNoData[curnt.street][questIdx][dateY] = {};
                // eslint-disable-next-line no-loop-func
                yesnoBarLabels.forEach(function(m: string){
                  addressYesNoData[curnt.street][questIdx][dateY][m] = [0,0,0];
                })
              }
              if ( typeof addressYesNoData['default-address'][questIdx][dateY] === 'undefined' ) {
                addressYesNoData['default-address'][questIdx][dateY] = {};
                // eslint-disable-next-line no-loop-func
                yesnoBarLabels.forEach(function(m: string){
                  addressYesNoData['default-address'][questIdx][dateY][m] = [0,0,0];
                })
              }

              // Populate yesnoData which we will use to create the chart
              if ( curnt.data[questIdx] === 'Yes' ) {
                // 1: Yes
                yesnoData[questIdx][dateY][dateM][0]++;
                addressYesNoData[curnt.street][questIdx][dateY][dateM][0]++;
                providerYesNoData[curnt.data['Service provider']][questIdx][dateY][dateM][0]++;
                // 1: No
                yesnoData[questIdx][dateY][dateM][1] += 0;
                addressYesNoData[curnt.street][questIdx][dateY][dateM][1] += 0;
                providerYesNoData[curnt.data['Service provider']][questIdx][dateY][dateM][1] += 0;
                // 1: Not Sure
                yesnoData[questIdx][dateY][dateM][2] += 0;
                addressYesNoData[curnt.street][questIdx][dateY][dateM][2] += 0;
                providerYesNoData[curnt.data['Service provider']][questIdx][dateY][dateM][2] += 0;
              }
              else if ( curnt.data[questIdx] === 'No' ) {
                // 1: Yes
                yesnoData[questIdx][dateY][dateM][0] += 0;
                addressYesNoData[curnt.street][questIdx][dateY][dateM][0] += 0;
                providerYesNoData[curnt.data['Service provider']][questIdx][dateY][dateM][0] += 0;
                // 1: No
                yesnoData[questIdx][dateY][dateM][1] ++;
                addressYesNoData[curnt.street][questIdx][dateY][dateM][1] ++;
                providerYesNoData[curnt.data['Service provider']][questIdx][dateY][dateM][1] ++;
                // 1: Not Sure
                yesnoData[questIdx][dateY][dateM][2] += 0;
                addressYesNoData[curnt.street][questIdx][dateY][dateM][2] += 0;
                providerYesNoData[curnt.data['Service provider']][questIdx][dateY][dateM][2] += 0;
              }
              else if ( curnt.data[questIdx] === 'Not Sure' ) {
                // 1: Yes
                yesnoData[questIdx][dateY][dateM][0] += 0;
                addressYesNoData[curnt.street][questIdx][dateY][dateM][0] += 0;
                providerYesNoData[curnt.data['Service provider']][questIdx][dateY][dateM][0] += 0;
                // 1: No
                yesnoData[questIdx][dateY][dateM][1] += 0;
                addressYesNoData[curnt.street][questIdx][dateY][dateM][1] += 0;
                providerYesNoData[curnt.data['Service provider']][questIdx][dateY][dateM][1] += 0;
                // 1: Not Sure
                yesnoData[questIdx][dateY][dateM][2] ++;
                addressYesNoData[curnt.street][questIdx][dateY][dateM][2] ++;
                providerYesNoData[curnt.data['Service provider']][questIdx][dateY][dateM][2] ++;
              }
          }
      })
    } 

    //console.log("QUESTION 17: " , JSON.stringify(q17))

    // Here we run again through all possible questions (for addresses and providers) and check if they are set with default values at all
    // ... some of them will not be because they didn't appear in all available years
    yearSelectObj.forEach(function(y: any){

      // check for addresses
      streetSelectObj.forEach(function(s: any){
        let i = 0;
        let questIdx: string = '';
        for (i=0; i <= 20; i++) {
          if ( i === 17 ) continue;
          questIdx = "yesno-" + i;
          if ( typeof addressYesNoData[s.value][questIdx][y.value] === 'undefined' ) {
            addressYesNoData[s.value][questIdx][y.value] = {};
            // eslint-disable-next-line no-loop-func
            yesnoBarLabels.forEach(function(m: string){
              addressYesNoData[s.value][questIdx][y.value][m] = [0,0,0];
            })
          }
        }
      })

      // check for providers
      providerSelectObj.forEach(function(p: any){
        let i = 0;
        let questIdx: string = '';
        for (i=0; i <= 20; i++) {
          if ( i === 17 ) continue;
          questIdx = "yesno-" + i;
          if ( typeof providerYesNoData[p.value][questIdx][y.value] === 'undefined' ) {
            providerYesNoData[p.value][questIdx][y.value] = {};
            // eslint-disable-next-line no-loop-func
            yesnoBarLabels.forEach(function(m: string){
              providerYesNoData[p.value][questIdx][y.value][m] = [0,0,0];
            })
          }
        }
      })
    })

    
    // sort alphabetically 
    // see: https://stackoverflow.com/questions/39850339/how-to-sort-object-array-based-on-key-in-typescript
    if ( providerSelectObj.length ) {
      providerSelectObj.sort((a, b) => a.value.localeCompare(b.value));
      providerSelectObj.unshift({"value": "",  "label": "All Providers"});
    }

    let yesnoBarOptions = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top' as const,
        },
        title: {
          display: true,
          text: yesnoTitle,
        },
      },
    };


    /**
     * Transform all months data into a sum for the year
     */
    const dataYes     = yesnoBarLabels.map((m) => yesnoData[yesnoValue][selectionYear][m][0])
    const dataNo      = yesnoBarLabels.map((m) => yesnoData[yesnoValue][selectionYear][m][1])
    const dataNotSure = yesnoBarLabels.map((m) => yesnoData[yesnoValue][selectionYear][m][2])
    
    let sumYes = dataYes.reduce((collector=0, num) => {
      return collector += num;
    }, 0 );
    let sumNo = dataNo.reduce((collector=0, num) => {
      return collector += num;
    }, 0 );
    let sumNotSure = dataNotSure.reduce((collector=0, num) => {
      return collector += num;
    }, 0 );

    
    const yesnoBarData = {
      labels: [selectionYear],
      datasets: [
        {
          label: 'Yes',
          data: [sumYes],
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
          label: 'No',
          data: [sumNo],
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
        {
          label: 'Not Sure',
          data: [sumNotSure],
          backgroundColor: 'rgba(53, 23, 235, 0.5)',
        },
      ],
    }; 

    
    /**
     * Transform all months data into a sum for the year -- For Addresses
     */
     const AddressdataYes     = yesnoBarLabels.map((m) => addressYesNoData[selectionAddress][yesnoValue][selectionYear][m][0])
     const AddressdataNo      = yesnoBarLabels.map((m) => addressYesNoData[selectionAddress][yesnoValue][selectionYear][m][1])
     const AddressdataNotSure = yesnoBarLabels.map((m) => addressYesNoData[selectionAddress][yesnoValue][selectionYear][m][2])
     
     let AddressSumYes = AddressdataYes.reduce((collector=0, num) => {
       return collector += num;
     }, 0 );
     let AddressSumNo = AddressdataNo.reduce((collector=0, num) => {
       return collector += num;
     }, 0 );
     let AddressSumNotSure = AddressdataNotSure.reduce((collector=0, num) => {
       return collector += num;
     }, 0 );


    const addressYesNoBarData = {
      labels: [selectionYear],
      datasets: [
        {
          label: 'Yes',
          data: [AddressSumYes],
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
          label: 'No',
          data: [AddressSumNo],
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
        {
          label: 'Not Sure',
          data: [AddressSumNotSure],
          backgroundColor: 'rgba(53, 23, 235, 0.5)',
        },
      ],
    };


    /**
     * Transform all months data into a sum for the year -- For Providers
     */
     const ProviderdataYes     = yesnoBarLabels.map((m) => providerYesNoData[selectionProvider][yesnoValue][selectionYear][m][0])
     const ProviderdataNo      = yesnoBarLabels.map((m) => providerYesNoData[selectionProvider][yesnoValue][selectionYear][m][1])
     const ProviderdataNotSure = yesnoBarLabels.map((m) => providerYesNoData[selectionProvider][yesnoValue][selectionYear][m][2])
     
     let ProviderSumYes = ProviderdataYes.reduce((collector=0, num) => {
       return collector += num;
     }, 0 );
     let ProviderSumNo = ProviderdataNo.reduce((collector=0, num) => {
       return collector += num;
     }, 0 );
     let ProviderSumNotSure = ProviderdataNotSure.reduce((collector=0, num) => {
       return collector += num;
     }, 0 );


    const providerYesNoBarData = {
      labels: [selectionYear],
      datasets: [
        {
          label: 'Yes',
          data: [ProviderSumYes],
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
          label: 'No',
          data: [ProviderSumNo],
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
        {
          label: 'Not Sure',
          data: [ProviderSumNotSure],
          backgroundColor: 'rgba(53, 23, 235, 0.5)',
        },
      ],
    };

    /**
     * Handle YesNo Select
     * @param selectedOption 
     */
    const handleYesNoSelect = (selectedOption:any) => {
      setYesnoTitle(selectedOption.label);
      setYesnoValue(selectedOption.value);
    }

    /**
     * Handle Year Select
     * @param selectedYear 
     */
    const handleYearSelect = (selectedYear:any) => {
      setSelectionYear(selectedYear.value);
    }

    /**
     * Handle Address Select
     * @param selectedAddress 
     * decides which of the bar charts to show (using addresses or not)
     * Update value of selectionAddress with selected value
     */
    const handleAddressSelect = (selectedAddress:any) => {
      if ( selectedAddress.value !== '' ) { 
        setSelectionAddress(selectedAddress.value);
        setAddressChartShow(true);
      }
      else setAddressChartShow(false);
    }

    /**
     * Handle Provider Select
     * @param selectedProvider 
     * decides which of the bar charts to show (using provider or not)
     * Update value of selectionAddress with selected value
     */
    const handleProviderSelect = (selectedProvider:any) => {
      if ( selectedProvider.value !== '' ) { 
        streetSelectObj = [];
  
        Object.entries(allAddresses).forEach(([v, i]) => {
          if ( v === selectedProvider.value ) {
            i.forEach(function(a: string){
              if ( addressesCollected.includes(a) ) streetSelectObj.push({"value":a,  "label":a});
            })
          }
        })
        streetSelectObj.sort((a, b) => a.value.localeCompare(b.value));
        streetSelectObj.unshift({"value": "",  "label": "All Addresses"});
        setStreetSelect(streetSelectObj);

        
        setSelectionProvider(selectedProvider.value);
        setProviderChartShow(true);
      }
      else setProviderChartShow(false);
    }

    const handleShowQ17 = () => {
      setQ17Checked(!q17checked);
    };

    const ClfSlider = (p:any) => {
      type boxType = Array<string>;
      let box:boxType;
      box = [];
      let greenlight:boolean;
      greenlight = true;

      // key is the years, value is object with months as arrays of texts
      Object.entries(p.q).forEach(([k1, v1]) => {
        if ( typeof p.a === 'string' && p.a !== 'default-address' && p.a !== '' ) {
          greenlight = (p.a === k1) ? true : false;
        }
        if ( greenlight && typeof v1 === 'object' && v1 !== null ) {
          Object.entries(v1).forEach(([k2, v2]) => {
            if ( typeof v2 === 'object' && v2 !== null ) {
              Object.entries(v2).forEach(([k3, v3]) => {
                if ( typeof v3 === 'object' && v3 !== null ) {
                  Object.entries(v3).forEach(([k, v]) => {
                    if (v !== '') {
                          box.push(v);
                    }
                  })
                }
              })
            }
          })
        }
      })
      return (
        <>
          <h3>If you are unhappy with anything at your home, who can help you fix the problem?</h3>
          <div className="q17-box-wrapper">
            {box.map((message, idx) => <Item key={idx} message={message} />)}
          </div>
        </>
      )
    }

    function Item(m:any) {
      return <div className="q17-box">{m.message}</div>;
    }

    return (
        <Card >
            <div id="sub-nav">
                <button id="download-graph-btn" onClick={onButtonClick}>Download</button>
            </div>
            
            <CardContent ref={ref} >
              {statSection === 'yesno' &&
                <>
                  <p className="reportTitle">Compare Yes-No questions by months</p>
                  <div className="yesno-selection-area">
                    <Select options={ yesNoQuestions } onChange={handleYesNoSelect} placeholder="Select Question" className="yesno-select" />
                    <Select options={ yearSelectObj } onChange={handleYearSelect} placeholder="Select Year" defaultValue={defaultYearSelect} />
                    <Select options={ providerSelectObj } onChange={handleProviderSelect} placeholder="Select Provider"  className="yesno-select-address" />
                    { !q17checked && providerChartShow &&
                      <Select options={ streetSelect } onChange={handleAddressSelect} placeholder="Select Address"  className="yesno-select-address" />
                    }
                    <div className="show-q17-checkbox">
                      <label>
                        <input  type="checkbox"
                                checked={q17checked}
                                onChange={handleShowQ17} />
                        Show Question 17
                      </label>
                    </div>
                  </div>
                  { !q17checked && !addressChartShow && !providerChartShow &&
                    <>
                    <Bar options={yesnoBarOptions} data={yesnoBarData} />
                    </>
                  }
                  { !q17checked && addressChartShow && 
                    <>
                    <Bar options={yesnoBarOptions} data={addressYesNoBarData} />
                    </>
                  }
                  { !q17checked && providerChartShow && !addressChartShow &&
                    <>
                    <Bar options={yesnoBarOptions} data={providerYesNoBarData} />
                    </>
                  }
                  { q17checked && selectionProvider === 'default-provider' &&
                    <div>
                      <p>Please select a provider to view their results for that question</p>
                    </div>
                  }
                  { q17checked && selectionProvider === '' &&
                    <div>
                      <p>Please select a provider to view their results for that question</p>
                    </div>
                  }
                  { q17checked && selectionProvider !== 'default-provider' && selectionProvider !== '' &&
                    <div>
                      <ClfSlider q={q17[selectionProvider]} a={selectionAddress} />
                    </div>
                  }
                </>
              }

              { (statSection === '' || statSection === null) &&
                <Doughnut 
                    className="diagrams-all"
                    options={cuOptions}
                    data={cuCharData}
                />
              }
            </CardContent>
        </Card>
)};

export default ValidReports;