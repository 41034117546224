import { List, 
    Datagrid, 
    TextField, 
    ReferenceField,
    DateField
} from 'react-admin';


export const NotTakenList = props => (
    <List {...props}>
        <Datagrid >
            <ReferenceField source="uid" reference="savedusers">
                <TextField source="username" />
            </ReferenceField>
            <TextField source="id" />
            <TextField source="street" />
            <TextField source="provider" />
            <TextField source="participants" />
            <TextField source="reason" />
            <DateField source="updatedAt" locales="en-AU" />
        </Datagrid>
    </List>
);

