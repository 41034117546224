import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';

const bcrypt = require('bcryptjs');

const apiUrl = (process.env.NODE_ENV === 'development' ) 
                ? 'http://localhost:3000/api'
                : 'https://valid8.valid.org.au/api'; //'https://valid8-nex-js-clf.vercel.app/api';
const httpClient = fetchUtils.fetchJson;

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            getAll: 1,
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify(params.filter),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json,
            total: parseInt(headers.get('X-Total-Count'), 10),
        }));
        // see example of dataProvider in https://marmelab.com/react-admin/Tutorial.html
        // I've removed the content-range to replace directly with x-count  
    },

    getOne: (resource, params) => {
         const query = {
             getOne: 1,
             id: params.id,
         };
         const url = `${apiUrl}/${resource}?${stringify(query)}`;
         return httpClient(url).then(({ json }) => ({
             data: json,
         }))
     },

    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ data: json }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json,
            total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }));
    },

    update: (resource, params) => {
        const query = {
            updOne: 1,
            id: params.data._id,
            data: params.data
        };
        const url = `${apiUrl}/${resource}`;
        return httpClient(url, {
            method: 'POST',
            body: JSON.stringify(query)
        }).then(({ json }) => ({ data: json })) 
    },

    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    create: (resource, params) => {

        // for users we need to encrypt the password:
        if ( params.data.username ) {
            params.data.hash = bcrypt.hashSync(params.data.password, 10);
            delete params.data.password;
        }

        // for Surveys we need the street name twice
        if ( params.data.street ) {
            params.data.data['Street Address'] = params.data.street;
        }

        const query = {
            createOne: 1,
            data: params.data,
        };
        return httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(query)
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        }))
    },

    delete: (resource, params) => {
        const query = {
            delOne: 1,
            id: params.id
        };
        const url = `${apiUrl}/${resource}`;
        return httpClient(url, {
            method: 'POST',
            body: JSON.stringify(query)
        }).then(({ json }) => ({ data: json })) 
    },

    // used for deleting surveys (single or multiple)
    deleteMany: (resource, params) => {
        const query = {
            delMany: 1,
            ids: params.ids
        };

        const url = `${apiUrl}/${resource}`;
        return httpClient(url, {
            method: 'POST',
            body: JSON.stringify(query)
        }).then(({ json }) => ({ data: json })) 
    }
};
