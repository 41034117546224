import * as React from 'react';
import { DashboardMenuItem, Menu, MenuItemLink } from 'react-admin';
import LabelIcon from '@material-ui/icons/Label';
import SurveyIcon from '@material-ui/icons/Ballot';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import BarChartIcon from '@material-ui/icons/BarChart';
import UserIcon from '@material-ui/icons/Group';
import TrendUpIcon from '@material-ui/icons/TrendingUp';
import DonutLIcon from '@material-ui/icons/DonutLarge';
import { makeStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const newSurveysURL = (process.env.NODE_ENV === 'development' ) 
                ? 'http://localhost:3000/'
                : 'https://valid8.valid.org.au/';


const useStyles = makeStyles({
    link: {
        color: "rgba(0, 0, 0, 0.54)",
        "&:hover": {
          color: "rgba(0, 0, 0, 0.74)"
        },
    },
    active: {
      color: 'rgba(0, 0, 0, 0.74)',
    },
    root: {
      width: '100%',
      maxWidth: 360,
    },
    nested: {
      paddingLeft: "44px",
    },
});


export const ValidMenu = (props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const handleClick = () => {
        setOpen(!open);
      };
    const ListItemLink = (props) => {
        return <ListItem button component="a" {...props} />;
    }

    return (
    <Menu {...props}>
        <DashboardMenuItem />
        <MenuItemLink to="/savedusers" primaryText="Manage Users" activeClassName={classes.active} className={classes.link} leftIcon={<UserIcon />}/>
        <MenuItemLink to="/" primaryText="Start New Survey" className={classes.link} leftIcon={<LabelIcon />} 
            onClick={() => { window.location.href = newSurveysURL; return null; }} 
        />
        <MenuItemLink to="/savedsurveys" primaryText="Change Surveys" activeClassName={classes.active} className={classes.link} leftIcon={<SurveyIcon />}/>
        <MenuItemLink to="/surveysnottaken" primaryText="Surveys Not Taken" activeClassName={classes.active} className={classes.link} leftIcon={<NotInterestedIcon />}/>
        {/*<MenuItemLink to="/ValidReports" primaryText="View Reports" activeClassName={classes.active} className={classes.link} leftIcon={<ChartIcon />}/>*/}
        <List
            component="nav"
            className={classes.root}
            >
            <ListItem button onClick={handleClick}>
                <ListItemIcon>
                <TrendUpIcon />
                </ListItemIcon>
                <ListItemText primary="View Reports" />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemLink button className={classes.nested} href="/#/ValidReports" >
                        <ListItemIcon>
                        <DonutLIcon />
                        </ListItemIcon>
                        <ListItemText primary="Completed" />
                    </ListItemLink>
                    <ListItemLink button className={classes.nested} href="/#/ValidReports?stat=yesno" >
                        <ListItemIcon>
                        <BarChartIcon />
                        </ListItemIcon>
                        <ListItemText primary="Yes..No" />
                    </ListItemLink>
                </List>
            </Collapse>
        </List>
    </Menu>
)};
