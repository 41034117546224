export const yesNoQuestions = [
    {"value":"yesno-1",  "label":"Do you make choices in your home?"},
    {"value":"yesno-2",  "label":"Do staff listen to your choices and make sure that they happen?"},
    {"value":"yesno-3",  "label":"Do you know if you have a service support plan?"},
    {"value":"yesno-4",  "label":"Do you feel safe in your home?"},
    {"value":"yesno-5",  "label":"Do you like how the staff treat you in your home?"},
    {"value":"yesno-6",  "label":"Do you do things that you are good at in your home?"},
    {"value":"yesno-7",  "label":"Do you talk to your family and friends about what's going on in your life?"},
    {"value":"yesno-8",  "label":"Do your house staff help you to be part of your community?"},
    {"value":"yesno-9",  "label":"Do you get to choose what you do in the community?"},
    {"value":"yesno-10", "label":"Are there things that you would like to do in your community but can't?"},
    {"value":"yesno-11", "label":"Do the staff at your house help you learn new things?"},
    {"value":"yesno-12", "label":"Do staff help you to learn to speak up for yourself?"},
    {"value":"yesno-13", "label":"Do you have resident meetings?"},
    {"value":"yesno-14", "label":"Do you have a say about what happens in your home?"},
    {"value":"yesno-15", "label":"Do you have a choice about what staff work in your home?"},
    {"value":"yesno-16", "label":"Do you get to talk to and give feedback to the big bosses at your service?"},
    {"value":"yesno-18", "label":"Have you ever felt like you will get in trouble for speaking up about a problem at your home?"},
    {"value":"yesno-19", "label":"Does your service give you information in a way you understand?"},
    {"value":"yesno-20", "label":"Do staff help you understand information you are given?"}
];

//[{"value":"","label":"All Addresses"},{"value":"Address 1","label":"Address 1"},{"value":"Address 2","label":"Address 2"},{"value":"Address 3","label":"Address 3"}]

export const allAddresses = {
    "Life Without Barriers": [],
    "Scope":["106 Victoria Street, Altona Meadows",
                    "8 Natalie Court, Bayswater",
                    "4 Galloway Grove, Bayswater North",
                    "1 Goff Street, Beaconsfield",
                    "2 McHarg Place, Beechworth",
                    "6 Kurrajong Court, Benalla",
                    "3 Dean Court, Colac",
                    "29 Sherwood Crescent, Dandenong North",
                    "Unit 2, 22 Salmond Street, Deer Park",
                    "131 Primrose Street, Essendon",
                    "100 Arthur Street, Fairfield",
                    "19 Chalmers Grove, Ferntree Gully",
                    "8 Pineville Avenue, Geelong West",
                    "35 McPhee Street, Hamilton",
                    "5 Collins Street, Kew",
                    "8 Keystone Crescent, Kew East",
                    "600 Anakie Road, Lovely Banks",
                    "Unit 1, 2 and 3 - 65 Albenca Street, Mentone",
                    "14-16B Glenburn Street, Newcomb",
                    "27 Balmoral Street, Portland",
                    "14 Hotham Street, Preston",
                    "130 Oban Road, Ringwood North",
                    "136-140 Rowan Street, Wangaratta",
                    "9 Reita Avenue, Wantirna South",
                    "4 Camellia Court, Wodonga"
                  ],
    "Aruma":["24 Bakers Road Coburg",
                    "48 Morley Street Glenroy",
                    "6 Forest Drive Kialla",
                    "51 Azalea Avenue Mill Park",
                    "5 Glenda Ann Court Mooroopna",
                    "20 Hughes Parade Reservoir",
                    "129 Rathcown Road Reservoir"
                  ],
    "Possability":["1/22 Blackie Ave, Ararat", 
                    "44 Tatyoon Rd, Ararat", 
                    "Unit 1/3 Wingoon Drive, Bendigo", 
                    "274 Midland Hwy, Bendigo",
                    "85 Killarney Drive, Melton",
                    "11 Hill St, Stawell",
                    "42 Barnes St, Stawell",
                    "6 Heritage Crt, Werribee"
                ],
    "Melba":["33 Thompson Street, Brown Hill",
                "186a Remembrance Drive, Cardigan",
                "30 Orwil Street, Frankston",
                "33-35 Burnside Street, Moe",
                "144 Moe South Road, Moe South",
                "32 Rollings Close, Rosebud"
            ],
}