import * as React from "react";
import { Card, CardContent, CardHeader } from '@material-ui/core';


const Dashboard = () => (
    <Card>
        <CardHeader title="VALID8 Admin Section" />
        <CardContent>
            This is the Valid8 Administration. 
            You will find here the Surveys that have been completed but also those that are not yet finished.
        </CardContent>
    </Card>
);

export default Dashboard;