import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    /* SearchInput, */
    EditButton,
    Edit,
    Create,
    BooleanInput,
    BooleanField,
    DateField,
    DateInput,
    TextInput,
    NumberInput,
    RadioButtonGroupInput,
    FormTab,
    TabbedForm,
} from 'react-admin';
import { format } from 'date-fns';
import './survey.css';

/* <SearchInput source="savedusers" alwaysOn />, */

const surveyFilter = [
    <ReferenceInput source="uid" label="User" reference="savedusers" allowEmpty>
        <SelectInput optionText="username" />
    </ReferenceInput>,
];
    /*<TextInput source="q" label="Search" alwaysOn />,*/


function handleStreetChange(event) {
    console.log(event.target.value);
} 



export const SavedsurveyList = props => (
    <List filters={surveyFilter} {...props}>
        <Datagrid rowClick="edit">
            <ReferenceField source="uid" reference="savedusers">
                <TextField source="username" />
            </ReferenceField>
            <TextField source="id" />
            <TextField source="street" />
            <BooleanField source="completed" />
            <DateField source="updatedAt" locales="en-AU" />
            <DateField source="createdAt" locales="en-AU" />
            <EditButton />
        </Datagrid>
    </List>
);

/* const listItems = data.map((d) =>
    // Wrong! The key should have been specified here:    
    <TextInput source={d} />  );
  return (
      {listItems}
  ); */


/* "_id":"61819b0144bbb7b5588bf1c8","id":2021110220935,"street":"Test Street 2",
"data":{"Street Address":"Test Street 2","Service provider":"Vaild8","Number of residents participating":"5","yesno-1":"Yes",
"yesno-1-Comment":"This is the second test with ID in the Database","small-respBy-1":"Resident","yesno-2":"No","yesno-3":"Not Sure",
"yesno-4":"Yes","yesno-5":"Not Sure","yesno-5-Comment":"Some more answers","small-respBy-5":"Staff","see-or-hear-5":
"What did we hear or see for question 5","yesno-6":"No"},"completed":true,"__v":0,"createdAt":"2021-11-02T20:09:37.483Z","updatedAt":"2021-11-02T20:09:37.483Z" */

const MyLabel = ({ txt }) => {

    return (
        <label className="surveyLabel">{txt}</label>
    );
}

// Component to define a default for User when creating a new Survey
const SelectInputDefaultValue = ({ optionText, ...rest }) => {
    const {choices} = rest;
    let defaultChoice = choices && choices.length>=1? choices[0]:""
    return (
        <SelectInput optionText={optionText} defaultValue={defaultChoice.id} {...rest}/>
    )
}

export const SavedsurveyEdit = props => {
    
    return (
    <Edit {...props}>

    <TabbedForm>
      <FormTab label="ID and Status:">
            <ReferenceField source="uid" reference="savedusers">
                <TextField source="username" />
            </ReferenceField>
            <TextInput disabled label={false} source="id" />
            <BooleanInput source="completed" />
            <DateInput disabled source="createdAt" options={{ format: 'DD/MM/YYYY' }} />
            <DateInput disabled source="updatedAt" options={{ format: 'DD/MM/YYYY' }} />
        </FormTab>

      <FormTab label="Intro page:">

            <SelectInput source="street" choices={[
                { id: 'Address 1', name: 'Address 1' },
                { id: 'Address 2', name: 'Address 2' },
                { id: 'Address 3', name: 'Address 3' },
                { id: 'Address 4', name: 'Address 4' },
                { id: 'Address 5', name: 'Address 5' },
                { id: 'Address 6', name: 'Address 6' },
            ]} />
            <TextInput label="Street (again)" source="data.Street Address" onChange={handleStreetChange} />
            <SelectInput
                source="data.Service provider"
                label="Service Provider"
                choices={[
                    { id: "Life Without Barriers", name: "Life Without Barriers" },
                    { id: "Scope", name: "Scope" },
                    { id: "Aruma", name: "Aruma" },
                    { id: "Possability", name: "Possability" },
                    { id: "Melba", name: "Melba" }
                ]}
                defaultValue={"Valid8"}
            />
            <NumberInput label="Number of Residents" source="data.Number of residents participating" />
        </FormTab>

      <FormTab label="Question 1:">
            <MyLabel txt="Do you make choices in your home?" />
            <RadioButtonGroupInput label={false} source="data.yesno-1" choices={[
                                                                                        { id: 'Yes', name: 'Yes' },
                                                                                        { id: 'No', name: 'No' },
                                                                                        { id: 'Not Sure', name: 'Not Sure' },
                                                                                    ]}/>
            <RadioButtonGroupInput label="Answered by:" source="data.small-respBy-1" choices={[
                                                                                        { id: 'Resident', name: 'Resident' },
                                                                                        { id: 'Staff', name: 'Staff' },
                                                                                    ]}/>
            <TextInput multiline label="Comment" source="data.yesno-1-Comment" />
            <TextInput multiline label="See or Hear?" source="data.see-or-hear-1" />
        </FormTab>

      <FormTab label="Question 2:">
            <MyLabel txt="Do staff listen to your choices and make sure that they happen?" />
            <RadioButtonGroupInput label={false} source="data.yesno-2" choices={[
                                                                                        { id: 'Yes', name: 'Yes' },
                                                                                        { id: 'No', name: 'No' },
                                                                                        { id: 'Not Sure', name: 'Not Sure' },
                                                                                    ]}/>
            <RadioButtonGroupInput label="Answered by:" source="data.small-respBy-2" choices={[
                                                                                        { id: 'Resident', name: 'Resident' },
                                                                                        { id: 'Staff', name: 'Staff' },
                                                                                    ]}/>
            <TextInput multiline label="Comment" source="data.yesno-2-Comment" />
            <TextInput multiline label="See or Hear?" source="data.see-or-hear-2" />
        </FormTab>

      <FormTab label="Question 3:">
            <MyLabel txt="Do you know if you have a service support plan?" />
            <RadioButtonGroupInput label={false} source="data.yesno-3" choices={[
                                                                                        { id: 'Yes', name: 'Yes' },
                                                                                        { id: 'No', name: 'No' },
                                                                                        { id: 'Not Sure', name: 'Not Sure' },
                                                                                    ]}/>
            <RadioButtonGroupInput label="Answered by:" source="data.small-respBy-3" choices={[
                                                                                        { id: 'Resident', name: 'Resident' },
                                                                                        { id: 'Staff', name: 'Staff' },
                                                                                    ]}/>
            <TextInput multiline label="Comment" source="data.yesno-3-Comment" />
            <TextInput multiline label="See or Hear?" source="data.see-or-hear-3" />
        </FormTab>

      <FormTab label="Question 4:">
            <MyLabel txt="Do you feel safe in your home?" />
            <RadioButtonGroupInput label={false} source="data.yesno-4" choices={[
                                                                                        { id: 'Yes', name: 'Yes' },
                                                                                        { id: 'No', name: 'No' },
                                                                                        { id: 'Not Sure', name: 'Not Sure' },
                                                                                    ]}/>
            <RadioButtonGroupInput label="Answered by:" source="data.small-respBy-4" choices={[
                                                                                        { id: 'Resident', name: 'Resident' },
                                                                                        { id: 'Staff', name: 'Staff' },
                                                                                    ]}/>
            <TextInput multiline label="Comment" source="data.yesno-4-Comment" />
            <TextInput multiline label="See or Hear?" source="data.see-or-hear-4" />
        </FormTab>

      <FormTab label="Question 5:">
            <MyLabel txt="Do you like how the staff treat you in your home?" />
            <RadioButtonGroupInput label={false} source="data.yesno-5" choices={[
                                                                                        { id: 'Yes', name: 'Yes' },
                                                                                        { id: 'No', name: 'No' },
                                                                                        { id: 'Not Sure', name: 'Not Sure' },
                                                                                    ]}/>
            <RadioButtonGroupInput label="Answered by:" source="data.small-respBy-5" choices={[
                                                                                        { id: 'Resident', name: 'Resident' },
                                                                                        { id: 'Staff', name: 'Staff' },
                                                                                    ]}/>
            <TextInput multiline label="Comment" source="data.yesno-5-Comment" />
            <TextInput multiline label="See or Hear?" source="data.see-or-hear-5" />
        </FormTab>

      <FormTab label="Question 6:">
            <MyLabel txt="Do you do things that you are good at in your home?" />
            <RadioButtonGroupInput label={false} source="data.yesno-6" choices={[
                                                                                        { id: 'Yes', name: 'Yes' },
                                                                                        { id: 'No', name: 'No' },
                                                                                        { id: 'Not Sure', name: 'Not Sure' },
                                                                                    ]}/>
            <RadioButtonGroupInput label="Answered by:" source="data.small-respBy-6" choices={[
                                                                                        { id: 'Resident', name: 'Resident' },
                                                                                        { id: 'Staff', name: 'Staff' },
                                                                                    ]}/>
            <TextInput multiline label="Comment" source="data.yesno-6-Comment" />
            <TextInput multiline label="See or Hear?" source="data.see-or-hear-6" />
        </FormTab>

      <FormTab label="Question 7:">
            <MyLabel txt="Do you talk to your family and friends about what's going on in your life?" />
            <RadioButtonGroupInput label={false} source="data.yesno-7" choices={[
                                                                                        { id: 'Yes', name: 'Yes' },
                                                                                        { id: 'No', name: 'No' },
                                                                                        { id: 'Not Sure', name: 'Not Sure' },
                                                                                    ]}/>
            <RadioButtonGroupInput label="Answered by:" source="data.small-respBy-7" choices={[
                                                                                        { id: 'Resident', name: 'Resident' },
                                                                                        { id: 'Staff', name: 'Staff' },
                                                                                    ]}/>
            <TextInput multiline label="Comment" source="data.yesno-7-Comment" />
            <TextInput multiline label="See or Hear?" source="data.see-or-hear-7" />
        </FormTab>

      <FormTab label="Question 8:">
            <MyLabel txt="Do your house staff help you to be part of your community?" />
            <RadioButtonGroupInput label={false} source="data.yesno-8" choices={[
                                                                                        { id: 'Yes', name: 'Yes' },
                                                                                        { id: 'No', name: 'No' },
                                                                                        { id: 'Not Sure', name: 'Not Sure' },
                                                                                    ]}/>
            <RadioButtonGroupInput label="Answered by:" source="data.small-respBy-8" choices={[
                                                                                        { id: 'Resident', name: 'Resident' },
                                                                                        { id: 'Staff', name: 'Staff' },
                                                                                    ]}/>
            <TextInput multiline label="Comment" source="data.yesno-8-Comment" />
            <TextInput multiline label="See or Hear?" source="data.see-or-hear-8" />
        </FormTab>

      <FormTab label="Question 9:">
            <MyLabel txt="Do you get to choose what you do in the community?" />
            <RadioButtonGroupInput label={false} source="data.yesno-9" choices={[
                                                                                        { id: 'Yes', name: 'Yes' },
                                                                                        { id: 'No', name: 'No' },
                                                                                        { id: 'Not Sure', name: 'Not Sure' },
                                                                                    ]}/>
            <RadioButtonGroupInput label="Answered by:" source="data.small-respBy-9" choices={[
                                                                                        { id: 'Resident', name: 'Resident' },
                                                                                        { id: 'Staff', name: 'Staff' },
                                                                                    ]}/>
            <TextInput multiline label="Comment" source="data.yesno-9-Comment" />
            <TextInput multiline label="See or Hear?" source="data.see-or-hear-9" />
        </FormTab>

      <FormTab label="Question 10:">
            <MyLabel txt="Are there things that you would like to do in your community but can't?" />
            <RadioButtonGroupInput label={false} source="data.yesno-10" choices={[
                                                                                        { id: 'Yes', name: 'Yes' },
                                                                                        { id: 'No', name: 'No' },
                                                                                        { id: 'Not Sure', name: 'Not Sure' },
                                                                                    ]}/>
            <RadioButtonGroupInput label="Answered by:" source="data.small-respBy-10" choices={[
                                                                                        { id: 'Resident', name: 'Resident' },
                                                                                        { id: 'Staff', name: 'Staff' },
                                                                                    ]}/>
            <TextInput multiline label="Comment" source="data.yesno-10-Comment" />
            <TextInput multiline label="See or Hear?" source="data.see-or-hear-10" />
        </FormTab>

      <FormTab label="Question 11:">
            <MyLabel txt="Do the staff at your house help you learn new things?" />
            <RadioButtonGroupInput label={false} source="data.yesno-11" choices={[
                                                                                        { id: 'Yes', name: 'Yes' },
                                                                                        { id: 'No', name: 'No' },
                                                                                        { id: 'Not Sure', name: 'Not Sure' },
                                                                                    ]}/>
            <RadioButtonGroupInput label="Answered by:" source="data.small-respBy-11" choices={[
                                                                                        { id: 'Resident', name: 'Resident' },
                                                                                        { id: 'Staff', name: 'Staff' },
                                                                                    ]}/>
            <TextInput multiline label="Comment" source="data.yesno-11-Comment" />
            <TextInput multiline label="See or Hear?" source="data.see-or-hear-11" />
        </FormTab>

      <FormTab label="Question 12:">
            <MyLabel txt="Do staff help you to learn to speak up for yourself?" />
            <RadioButtonGroupInput label={false} source="data.yesno-12" choices={[
                                                                                        { id: 'Yes', name: 'Yes' },
                                                                                        { id: 'No', name: 'No' },
                                                                                        { id: 'Not Sure', name: 'Not Sure' },
                                                                                    ]}/>
            <RadioButtonGroupInput label="Answered by:" source="data.small-respBy-12" choices={[
                                                                                        { id: 'Resident', name: 'Resident' },
                                                                                        { id: 'Staff', name: 'Staff' },
                                                                                    ]}/>
            <TextInput multiline label="Comment" source="data.yesno-12-Comment" />
            <TextInput multiline label="See or Hear?" source="data.see-or-hear-12" />
        </FormTab>

      <FormTab style={{flexWrap:"wrap"}} label="Question 13:">
            <MyLabel txt="Do you have resident meetings?" />
            <RadioButtonGroupInput label={false} source="data.yesno-13" choices={[
                                                                                        { id: 'Yes', name: 'Yes' },
                                                                                        { id: 'No', name: 'No' },
                                                                                        { id: 'Not Sure', name: 'Not Sure' },
                                                                                    ]}/>
            <RadioButtonGroupInput label="Answered by:" source="data.small-respBy-13" choices={[
                                                                                        { id: 'Resident', name: 'Resident' },
                                                                                        { id: 'Staff', name: 'Staff' },
                                                                                    ]}/>
            <TextInput multiline label="Comment" source="data.yesno-13-Comment" />
            <TextInput multiline label="See or Hear?" source="data.see-or-hear-13" />
        </FormTab>

      <FormTab style={{flexWrap:"wrap"}} label="Question 14:">
            <MyLabel txt="Do you have a say about what happens in your home?" />
            <RadioButtonGroupInput label={false} source="data.yesno-14" choices={[
                                                                                        { id: 'Yes', name: 'Yes' },
                                                                                        { id: 'No', name: 'No' },
                                                                                        { id: 'Not Sure', name: 'Not Sure' },
                                                                                    ]}/>
            <RadioButtonGroupInput label="Answered by:" source="data.small-respBy-14" choices={[
                                                                                        { id: 'Resident', name: 'Resident' },
                                                                                        { id: 'Staff', name: 'Staff' },
                                                                                    ]}/>
            <TextInput multiline label="Comment" source="data.yesno-14-Comment" />
            <TextInput multiline label="See or Hear?" source="data.see-or-hear-14" />
        </FormTab>

      <FormTab style={{flexWrap:"wrap"}} label="Question 15:">
            <MyLabel txt="Do you have a choice about what staff work in your home?" />
            <RadioButtonGroupInput label={false} source="data.yesno-15" choices={[
                                                                                        { id: 'Yes', name: 'Yes' },
                                                                                        { id: 'No', name: 'No' },
                                                                                        { id: 'Not Sure', name: 'Not Sure' },
                                                                                    ]}/>
            <RadioButtonGroupInput label="Answered by:" source="data.small-respBy-15" choices={[
                                                                                        { id: 'Resident', name: 'Resident' },
                                                                                        { id: 'Staff', name: 'Staff' },
                                                                                    ]}/>
            <TextInput multiline label="Comment" source="data.yesno-15-Comment" />
            <TextInput multiline label="See or Hear?" source="data.see-or-hear-15" />
        </FormTab>

      <FormTab style={{flexWrap:"wrap"}} label="Question 16:">
            <MyLabel txt="Do you get to talk to and give feedback to the big bosses at your service?" />
            <RadioButtonGroupInput label={false} source="data.yesno-16" choices={[
                                                                                        { id: 'Yes', name: 'Yes' },
                                                                                        { id: 'No', name: 'No' },
                                                                                        { id: 'Not Sure', name: 'Not Sure' },
                                                                                    ]}/>
            <RadioButtonGroupInput label="Answered by:" source="data.small-respBy-16" choices={[
                                                                                        { id: 'Resident', name: 'Resident' },
                                                                                        { id: 'Staff', name: 'Staff' },
                                                                                    ]}/>
            <TextInput multiline label="Comment" source="data.yesno-16-Comment" />
            <TextInput multiline label="See or Hear?" source="data.see-or-hear-16" />
        </FormTab>

      <FormTab style={{flexWrap:"wrap"}} label="Question 17:">
            <MyLabel txt="If you are unhappy with anything at your home, who can help you fix the problem?" />
            <TextInput multiline label="Comment" source="data.question76" />
            <RadioButtonGroupInput label="Answered by:" source="data.small-respBy-17" choices={[
                                                                                        { id: 'Resident', name: 'Resident' },
                                                                                        { id: 'Staff', name: 'Staff' },
                                                                                    ]}/>
        </FormTab>

    <FormTab style={{flexWrap:"wrap"}} label="Question 18:">
            <MyLabel txt="Have you ever felt like you will get in trouble for speaking up about a problem at your home?" />
            <RadioButtonGroupInput label={false} source="data.yesno-18" choices={[
                                                                                        { id: 'Yes', name: 'Yes' },
                                                                                        { id: 'No', name: 'No' },
                                                                                        { id: 'Not Sure', name: 'Not Sure' },
                                                                                    ]}/>
            <RadioButtonGroupInput label="Answered by:" source="data.small-respBy-18" choices={[
                                                                                        { id: 'Resident', name: 'Resident' },
                                                                                        { id: 'Staff', name: 'Staff' },
                                                                                    ]}/>
            <TextInput multiline label="Comment" source="data.yesno-18-Comment" />
            <TextInput multiline label="See or Hear?" source="data.see-or-hear-18" />
        </FormTab>

      <FormTab style={{flexWrap:"wrap"}} label="Question 19:">
            <MyLabel txt="Does your service give you information in a way you understand?" />
            <RadioButtonGroupInput label={false} source="data.yesno-19" choices={[
                                                                                        { id: 'Yes', name: 'Yes' },
                                                                                        { id: 'No', name: 'No' },
                                                                                        { id: 'Not Sure', name: 'Not Sure' },
                                                                                    ]}/>
            <RadioButtonGroupInput label="Answered by:" source="data.small-respBy-19" choices={[
                                                                                        { id: 'Resident', name: 'Resident' },
                                                                                        { id: 'Staff', name: 'Staff' },
                                                                                    ]}/>
            <TextInput multiline label="Comment" source="data.yesno-19-Comment" />
            <TextInput multiline label="See or Hear?" source="data.see-or-hear-19" />
        </FormTab>

      <FormTab style={{flexWrap:"wrap"}} label="Question 20:">
            <MyLabel txt="Do staff help you understand information you are given?" />
            <RadioButtonGroupInput label={false} source="data.yesno-20" choices={[
                                                                                        { id: 'Yes', name: 'Yes' },
                                                                                        { id: 'No', name: 'No' },
                                                                                        { id: 'Not Sure', name: 'Not Sure' },
                                                                                    ]}/>
            <RadioButtonGroupInput label="Answered by:" source="data.small-respBy-20" choices={[
                                                                                        { id: 'Resident', name: 'Resident' },
                                                                                        { id: 'Staff', name: 'Staff' },
                                                                                    ]}/>
            <TextInput multiline label="Comment" source="data.yesno-20-Comment" />
            <TextInput multiline label="See or Hear?" source="data.see-or-hear-20" />
        </FormTab>

      <FormTab style={{flexWrap:"wrap"}} label="Question 21:">
            <MyLabel txt="This is the end of the survey. Is there anything else you want to say?" />
            <TextInput multiline label="Comment" source="data.question84" />

            <RadioButtonGroupInput label="Answered by:" source="data.small-respBy-21" choices={[
                                                                                        { id: 'Resident', name: 'Resident' },
                                                                                        { id: 'Staff', name: 'Staff' },
                                                                                    ]}/>
        </FormTab>

    </TabbedForm>
    </Edit>
)};


export const SavedsurveyCreate = props => {

    const timeId = format(new Date(), 'yyyyMMddHHmmss')
    
    return (
    <Create {...props}>

    <TabbedForm>
      <FormTab label="ID, User and Status:">

            <ReferenceInput source="uid" label="User" reference="savedusers">
                <SelectInputDefaultValue optionText="username"/>
            </ReferenceInput>
            <TextInput disabled label="Survey ID (not editable)" source="id" initialValue={timeId} />
            <BooleanInput source="completed" />
        </FormTab>

        <FormTab label="Intro page:">

            <SelectInput source="street" choices={[
                { id: 'Address 1', name: 'Address 1' },
                { id: 'Address 2', name: 'Address 2' },
                { id: 'Address 3', name: 'Address 3' },
                { id: 'Address 4', name: 'Address 4' },
                { id: 'Address 5', name: 'Address 5' },
                { id: 'Address 6', name: 'Address 6' },
            ]} />
            <SelectInput
                source="data.Service provider"
                label="Service Provider"
                choices={[
                    { id: "Life Without Barriers", name: "Life Without Barriers" },
                    { id: "Scope", name: "Scope" },
                    { id: "Aruma", name: "Aruma" },
                    { id: "Possability", name: "Possability" },
                    { id: "Melba", name: "Melba" }
                ]}
                defaultValue={"Valid8"}
            />
            <NumberInput label="Number of Residents" source="data.Number of residents participating" />
        </FormTab>

        <FormTab label="Question 1:">
            <MyLabel txt="Do you make choices in your home?" />
            <RadioButtonGroupInput label={false} source="data.yesno-1" choices={[
                                                                                        { id: 'Yes', name: 'Yes' },
                                                                                        { id: 'No', name: 'No' },
                                                                                        { id: 'Not Sure', name: 'Not Sure' },
                                                                                    ]}/>
            <RadioButtonGroupInput label="Answered by:" source="data.small-respBy-1" choices={[
                                                                                        { id: 'Resident', name: 'Resident' },
                                                                                        { id: 'Staff', name: 'Staff' },
                                                                                    ]}/>
            <TextInput multiline label="Comment" source="data.yesno-1-Comment" />
            <TextInput multiline label="See or Hear?" source="data.see-or-hear-1" />
        </FormTab>

        <FormTab label="Question 2:">
            <MyLabel txt="Do staff listen to your choices and make sure that they happen?" />
            <RadioButtonGroupInput label={false} source="data.yesno-2" choices={[
                                                                                        { id: 'Yes', name: 'Yes' },
                                                                                        { id: 'No', name: 'No' },
                                                                                        { id: 'Not Sure', name: 'Not Sure' },
                                                                                    ]}/>
            <RadioButtonGroupInput label="Answered by:" source="data.small-respBy-2" choices={[
                                                                                        { id: 'Resident', name: 'Resident' },
                                                                                        { id: 'Staff', name: 'Staff' },
                                                                                    ]}/>
            <TextInput multiline label="Comment" source="data.yesno-2-Comment" />
            <TextInput multiline label="See or Hear?" source="data.see-or-hear-2" />
        </FormTab>

        <FormTab label="Question 3:">
            <MyLabel txt="Do you know if you have a service support plan?" />
            <RadioButtonGroupInput label={false} source="data.yesno-3" choices={[
                                                                                        { id: 'Yes', name: 'Yes' },
                                                                                        { id: 'No', name: 'No' },
                                                                                        { id: 'Not Sure', name: 'Not Sure' },
                                                                                    ]}/>
            <RadioButtonGroupInput label="Answered by:" source="data.small-respBy-3" choices={[
                                                                                        { id: 'Resident', name: 'Resident' },
                                                                                        { id: 'Staff', name: 'Staff' },
                                                                                    ]}/>
            <TextInput multiline label="Comment" source="data.yesno-3-Comment" />
            <TextInput multiline label="See or Hear?" source="data.see-or-hear-3" />
        </FormTab>

        <FormTab label="Question 4:">
            <MyLabel txt="Do you feel safe in your home?" />
            <RadioButtonGroupInput label={false} source="data.yesno-4" choices={[
                                                                                        { id: 'Yes', name: 'Yes' },
                                                                                        { id: 'No', name: 'No' },
                                                                                        { id: 'Not Sure', name: 'Not Sure' },
                                                                                    ]}/>
            <RadioButtonGroupInput label="Answered by:" source="data.small-respBy-4" choices={[
                                                                                        { id: 'Resident', name: 'Resident' },
                                                                                        { id: 'Staff', name: 'Staff' },
                                                                                    ]}/>
            <TextInput multiline label="Comment" source="data.yesno-4-Comment" />
            <TextInput multiline label="See or Hear?" source="data.see-or-hear-4" />
        </FormTab>

        <FormTab label="Question 5:">
            <MyLabel txt="Do you like how the staff treat you in your home?" />
            <RadioButtonGroupInput label={false} source="data.yesno-5" choices={[
                                                                                        { id: 'Yes', name: 'Yes' },
                                                                                        { id: 'No', name: 'No' },
                                                                                        { id: 'Not Sure', name: 'Not Sure' },
                                                                                    ]}/>
            <RadioButtonGroupInput label="Answered by:" source="data.small-respBy-5" choices={[
                                                                                        { id: 'Resident', name: 'Resident' },
                                                                                        { id: 'Staff', name: 'Staff' },
                                                                                    ]}/>
            <TextInput multiline label="Comment" source="data.yesno-5-Comment" />
            <TextInput multiline label="See or Hear?" source="data.see-or-hear-5" />
        </FormTab>

        <FormTab label="Question 6:">
            <MyLabel txt="Do you do things that you are good at in your home?" />
            <RadioButtonGroupInput label={false} source="data.yesno-6" choices={[
                                                                                        { id: 'Yes', name: 'Yes' },
                                                                                        { id: 'No', name: 'No' },
                                                                                        { id: 'Not Sure', name: 'Not Sure' },
                                                                                    ]}/>
            <RadioButtonGroupInput label="Answered by:" source="data.small-respBy-6" choices={[
                                                                                        { id: 'Resident', name: 'Resident' },
                                                                                        { id: 'Staff', name: 'Staff' },
                                                                                    ]}/>
            <TextInput multiline label="Comment" source="data.yesno-6-Comment" />
            <TextInput multiline label="See or Hear?" source="data.see-or-hear-6" />
        </FormTab>

        <FormTab label="Question 7:">
            <MyLabel txt="Do you talk to your family and friends about what's going on in your life?" />
            <RadioButtonGroupInput label={false} source="data.yesno-7" choices={[
                                                                                        { id: 'Yes', name: 'Yes' },
                                                                                        { id: 'No', name: 'No' },
                                                                                        { id: 'Not Sure', name: 'Not Sure' },
                                                                                    ]}/>
            <RadioButtonGroupInput label="Answered by:" source="data.small-respBy-7" choices={[
                                                                                        { id: 'Resident', name: 'Resident' },
                                                                                        { id: 'Staff', name: 'Staff' },
                                                                                    ]}/>
            <TextInput multiline label="Comment" source="data.yesno-7-Comment" />
            <TextInput multiline label="See or Hear?" source="data.see-or-hear-7" />
        </FormTab>

        <FormTab label="Question 8:">
            <MyLabel txt="Do your house staff help you to be part of your community?" />
            <RadioButtonGroupInput label={false} source="data.yesno-8" choices={[
                                                                                        { id: 'Yes', name: 'Yes' },
                                                                                        { id: 'No', name: 'No' },
                                                                                        { id: 'Not Sure', name: 'Not Sure' },
                                                                                    ]}/>
            <RadioButtonGroupInput label="Answered by:" source="data.small-respBy-8" choices={[
                                                                                        { id: 'Resident', name: 'Resident' },
                                                                                        { id: 'Staff', name: 'Staff' },
                                                                                    ]}/>
            <TextInput multiline label="Comment" source="data.yesno-8-Comment" />
            <TextInput multiline label="See or Hear?" source="data.see-or-hear-8" />
        </FormTab>

        <FormTab label="Question 9:">
            <MyLabel txt="Do you get to choose what you do in the community?" />
            <RadioButtonGroupInput label={false} source="data.yesno-9" choices={[
                                                                                        { id: 'Yes', name: 'Yes' },
                                                                                        { id: 'No', name: 'No' },
                                                                                        { id: 'Not Sure', name: 'Not Sure' },
                                                                                    ]}/>
            <RadioButtonGroupInput label="Answered by:" source="data.small-respBy-9" choices={[
                                                                                        { id: 'Resident', name: 'Resident' },
                                                                                        { id: 'Staff', name: 'Staff' },
                                                                                    ]}/>
            <TextInput multiline label="Comment" source="data.yesno-9-Comment" />
            <TextInput multiline label="See or Hear?" source="data.see-or-hear-9" />
        </FormTab>

        <FormTab label="Question 10:">
            <MyLabel txt="Are there things that you would like to do in your community but can't?" />
            <RadioButtonGroupInput label={false} source="data.yesno-10" choices={[
                                                                                        { id: 'Yes', name: 'Yes' },
                                                                                        { id: 'No', name: 'No' },
                                                                                        { id: 'Not Sure', name: 'Not Sure' },
                                                                                    ]}/>
            <RadioButtonGroupInput label="Answered by:" source="data.small-respBy-10" choices={[
                                                                                        { id: 'Resident', name: 'Resident' },
                                                                                        { id: 'Staff', name: 'Staff' },
                                                                                    ]}/>
            <TextInput multiline label="Comment" source="data.yesno-10-Comment" />
            <TextInput multiline label="See or Hear?" source="data.see-or-hear-10" />
        </FormTab>

        <FormTab label="Question 11:">
            <MyLabel txt="Do the staff at your house help you learn new things?" />
            <RadioButtonGroupInput label={false} source="data.yesno-11" choices={[
                                                                                        { id: 'Yes', name: 'Yes' },
                                                                                        { id: 'No', name: 'No' },
                                                                                        { id: 'Not Sure', name: 'Not Sure' },
                                                                                    ]}/>
            <RadioButtonGroupInput label="Answered by:" source="data.small-respBy-11" choices={[
                                                                                        { id: 'Resident', name: 'Resident' },
                                                                                        { id: 'Staff', name: 'Staff' },
                                                                                    ]}/>
            <TextInput multiline label="Comment" source="data.yesno-11-Comment" />
            <TextInput multiline label="See or Hear?" source="data.see-or-hear-11" />
        </FormTab>

        <FormTab label="Question 12:">
            <MyLabel txt="Do staff help you to learn to speak up for yourself?" />
            <RadioButtonGroupInput label={false} source="data.yesno-12" choices={[
                                                                                        { id: 'Yes', name: 'Yes' },
                                                                                        { id: 'No', name: 'No' },
                                                                                        { id: 'Not Sure', name: 'Not Sure' },
                                                                                    ]}/>
            <RadioButtonGroupInput label="Answered by:" source="data.small-respBy-12" choices={[
                                                                                        { id: 'Resident', name: 'Resident' },
                                                                                        { id: 'Staff', name: 'Staff' },
                                                                                    ]}/>
            <TextInput multiline label="Comment" source="data.yesno-12-Comment" />
            <TextInput multiline label="See or Hear?" source="data.see-or-hear-12" />
        </FormTab>

        <FormTab style={{flexWrap:"wrap"}} label="Question 13:">
            <MyLabel txt="Do you have resident meetings?" />
            <RadioButtonGroupInput label={false} source="data.yesno-13" choices={[
                                                                                        { id: 'Yes', name: 'Yes' },
                                                                                        { id: 'No', name: 'No' },
                                                                                        { id: 'Not Sure', name: 'Not Sure' },
                                                                                    ]}/>
            <RadioButtonGroupInput label="Answered by:" source="data.small-respBy-13" choices={[
                                                                                        { id: 'Resident', name: 'Resident' },
                                                                                        { id: 'Staff', name: 'Staff' },
                                                                                    ]}/>
            <TextInput multiline label="Comment" source="data.yesno-13-Comment" />
            <TextInput multiline label="See or Hear?" source="data.see-or-hear-13" />
        </FormTab>

        <FormTab style={{flexWrap:"wrap"}} label="Question 14:">
            <MyLabel txt="Do you have a say about what happens in your home?" />
            <RadioButtonGroupInput label={false} source="data.yesno-14" choices={[
                                                                                        { id: 'Yes', name: 'Yes' },
                                                                                        { id: 'No', name: 'No' },
                                                                                        { id: 'Not Sure', name: 'Not Sure' },
                                                                                    ]}/>
            <RadioButtonGroupInput label="Answered by:" source="data.small-respBy-14" choices={[
                                                                                        { id: 'Resident', name: 'Resident' },
                                                                                        { id: 'Staff', name: 'Staff' },
                                                                                    ]}/>
            <TextInput multiline label="Comment" source="data.yesno-14-Comment" />
            <TextInput multiline label="See or Hear?" source="data.see-or-hear-14" />
        </FormTab>

        <FormTab style={{flexWrap:"wrap"}} label="Question 15:">
            <MyLabel txt="Do you have a choice about what staff work in your home?" />
            <RadioButtonGroupInput label={false} source="data.yesno-15" choices={[
                                                                                        { id: 'Yes', name: 'Yes' },
                                                                                        { id: 'No', name: 'No' },
                                                                                        { id: 'Not Sure', name: 'Not Sure' },
                                                                                    ]}/>
            <RadioButtonGroupInput label="Answered by:" source="data.small-respBy-15" choices={[
                                                                                        { id: 'Resident', name: 'Resident' },
                                                                                        { id: 'Staff', name: 'Staff' },
                                                                                    ]}/>
            <TextInput multiline label="Comment" source="data.yesno-15-Comment" />
            <TextInput multiline label="See or Hear?" source="data.see-or-hear-15" />
        </FormTab>

        <FormTab style={{flexWrap:"wrap"}} label="Question 16:">
            <MyLabel txt="Do you get to talk to and give feedback to the big bosses at your service?" />
            <RadioButtonGroupInput label={false} source="data.yesno-16" choices={[
                                                                                        { id: 'Yes', name: 'Yes' },
                                                                                        { id: 'No', name: 'No' },
                                                                                        { id: 'Not Sure', name: 'Not Sure' },
                                                                                    ]}/>
            <RadioButtonGroupInput label="Answered by:" source="data.small-respBy-16" choices={[
                                                                                        { id: 'Resident', name: 'Resident' },
                                                                                        { id: 'Staff', name: 'Staff' },
                                                                                    ]}/>
            <TextInput multiline label="Comment" source="data.yesno-16-Comment" />
            <TextInput multiline label="See or Hear?" source="data.see-or-hear-16" />
        </FormTab>

        <FormTab style={{flexWrap:"wrap"}} label="Question 17:">
            <MyLabel txt="If you are unhappy with anything at your home, who can help you fix the problem?" />
            <TextInput multiline label="Comment" source="data.question76" />
            
        </FormTab>

        <FormTab style={{flexWrap:"wrap"}} label="Question 18:">
            <MyLabel txt="Have you ever felt like you will get in trouble for speaking up about a problem at your home?" />
            <RadioButtonGroupInput label={false} source="data.yesno-18" choices={[
                                                                                        { id: 'Yes', name: 'Yes' },
                                                                                        { id: 'No', name: 'No' },
                                                                                        { id: 'Not Sure', name: 'Not Sure' },
                                                                                    ]}/>
            <RadioButtonGroupInput label="Answered by:" source="data.small-respBy-18" choices={[
                                                                                        { id: 'Resident', name: 'Resident' },
                                                                                        { id: 'Staff', name: 'Staff' },
                                                                                    ]}/>
            <TextInput multiline label="Comment" source="data.yesno-18-Comment" />
            <TextInput multiline label="See or Hear?" source="data.see-or-hear-18" />
        </FormTab>

        <FormTab style={{flexWrap:"wrap"}} label="Question 19:">
            <MyLabel txt="Does your service give you information in a way you understand?" />
            <RadioButtonGroupInput label={false} source="data.yesno-19" choices={[
                                                                                        { id: 'Yes', name: 'Yes' },
                                                                                        { id: 'No', name: 'No' },
                                                                                        { id: 'Not Sure', name: 'Not Sure' },
                                                                                    ]}/>
            <RadioButtonGroupInput label="Answered by:" source="data.small-respBy-19" choices={[
                                                                                        { id: 'Resident', name: 'Resident' },
                                                                                        { id: 'Staff', name: 'Staff' },
                                                                                    ]}/>
            <TextInput multiline label="Comment" source="data.yesno-19-Comment" />
            <TextInput multiline label="See or Hear?" source="data.see-or-hear-19" />
        </FormTab>

        <FormTab style={{flexWrap:"wrap"}} label="Question 20:">
            <MyLabel txt="Do staff help you understand information you are given?" />
            <RadioButtonGroupInput label={false} source="data.yesno-20" choices={[
                                                                                        { id: 'Yes', name: 'Yes' },
                                                                                        { id: 'No', name: 'No' },
                                                                                        { id: 'Not Sure', name: 'Not Sure' },
                                                                                    ]}/>
            <RadioButtonGroupInput label="Answered by:" source="data.small-respBy-20" choices={[
                                                                                        { id: 'Resident', name: 'Resident' },
                                                                                        { id: 'Staff', name: 'Staff' },
                                                                                    ]}/>
            <TextInput multiline label="Comment" source="data.yesno-20-Comment" />
            <TextInput multiline label="See or Hear?" source="data.see-or-hear-20" />
        </FormTab>

        <FormTab style={{flexWrap:"wrap"}} label="Question 21:">
            <MyLabel txt="This is the end of the survey. Is there anything else you want to say?" />
            <TextInput multiline label="Comment" source="data.question84" />

            <RadioButtonGroupInput label="Answered by:" source="data.small-respBy-21" choices={[
                                                                                        { id: 'Resident', name: 'Resident' },
                                                                                        { id: 'Staff', name: 'Staff' },
                                                                                    ]}/>
        </FormTab>

        </TabbedForm>
    </Create>
)};

