import * as React from "react";
import { Admin, Resource } from 'react-admin';
import { SaveduserList, SaveduserEdit, SaveduserCreate } from './components/users';
import { SavedsurveyList, SavedsurveyEdit, SavedsurveyCreate } from './components/surveys';
import { NotTakenList } from './components/nottaken';
import SurveyIcon from '@material-ui/icons/Ballot';
import NoInterestIcon from '@material-ui/icons/NotInterested';
import UserIcon from '@material-ui/icons/Group';
import Dashboard from './components/Dashboard';
import authProvider from './components/authProvider';
import dataProvider from './lib/dataProvider';
import { ValidLayout }  from './Layout';
import customRoutes from './customRoutes';


const App = () => {

  React.useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = "Valid8 - Admin";
  }, []);

  return (
  <Admin customRoutes={customRoutes} layout={ValidLayout} dashboard={Dashboard} authProvider={authProvider} dataProvider={dataProvider}>
    <Resource name="savedusers" options={{ label: 'Manage Users' }} list={SaveduserList} icon={UserIcon} edit={SaveduserEdit} create={SaveduserCreate} />
    <Resource name="savedsurveys" options={{ label: 'Review/Change Surveys' }} list={SavedsurveyList} icon={SurveyIcon} edit={SavedsurveyEdit} create={SavedsurveyCreate} />
    <Resource name="surveysnottaken" options={{ label: 'Surveys Not Taken' }} list={NotTakenList} icon={NoInterestIcon}  />
  </Admin>
)};

export default App;
