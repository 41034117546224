import { List, 
    Datagrid, 
    TextField, 
    BooleanField, 
    DateField,
    EditButton,
    Edit,
    Create,
    BooleanInput,
    DateInput,
    TextInput,
    SimpleForm
} from 'react-admin';
import { format } from 'date-fns';


export const SaveduserList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            {/*<ReferenceField source="id" reference="savedsurveys"><TextField source="uid" /></ReferenceField>*/}
            <TextField source="id" />
            <TextField source="firstName" />
            <TextField source="lastName" />
            <TextField source="username" />
            <BooleanField source="active" />
            <DateField source="updatedAt" locales="en-AU" />
            <EditButton />
        </Datagrid>
    </List>
);


export const SaveduserEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            {/*<ReferenceInput source="_id" reference="s"><SelectInput optionText="id" /></ReferenceInput>*/}
            <TextInput disabled source="id" />
            <TextInput source="firstName" />
            <TextInput source="lastName" />
            <TextInput source="username" />
            <BooleanInput source="active" />
            <DateInput disabled source="createdAt" options={{ format: 'DD/MM/YYYY' }} />
            <DateInput disabled source="updatedAt" options={{ format: 'DD/MM/YYYY' }} />
        </SimpleForm>
    </Edit>
);

export const SaveduserCreate = props => {

    const timeId = format(new Date(), 'yyyyMMddHHmmss')

    return (
        <Create {...props}>
            <SimpleForm>
                {/*<ReferenceInput source="_id" reference="s"><SelectInput optionText="id" /></ReferenceInput>*/}
                <TextInput disabled label={false} source="id" initialValue={timeId} />
                <TextInput source="firstName" />
                <TextInput source="lastName" />
                <TextInput source="username" />
                <TextInput source="password" />
                <BooleanInput source="active" />
            </SimpleForm>
        </Create>
)};
